<template>
  <b-row>
    <b-col cols="12">
      <h2>代理列表</h2>
      <div style="display: flex; flex-direction: row; align-items: center;">
        <b-form-input v-model="text" placeholder="id/邮箱/昵称"></b-form-input>
        <b-button variant="primary" class="btn-icon-only rounded-circle"
          style="margin-left: 15px; min-width: 40px; min-height: 40px;" @click="loaData">
          <i class="ri-search-line remix-icon"></i>
        </b-button>
        <b-button variant="primary" class="btn-icon-only rounded-circle"
          style="margin-left: 15px; min-width: 40px; min-height: 40px;" @click="dialogVisible = true">
          <i class="iconly-Bold-AddUser"></i>
        </b-button>
      </div>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-table-simple responsive class="border rounded mb-0 hp-border-color-dark-80">
        <b-thead>
          <b-tr>
            <b-th>ID</b-th>
            <b-th>邮箱</b-th>
            <b-th>昵称</b-th>
            <b-th>ip</b-th>
            <b-th>地址</b-th>
            <b-th>权限</b-th>
            <b-th>代理状态</b-th>
            <b-th>鱼塘数量</b-th>
            <b-th>创建时间</b-th>
            <b-th class="text-right">操作</b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <b-tr v-for="(li, index) in list" :key="index">
            <b-td style="min-width: 100px; width: 100px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{ li.id }}
              </span>
            </b-td>
            <b-td style="min-width: 100px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{ li.email }}
              </span>
            </b-td>
            <b-td style="min-width: 100px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{ li.nickname }}
              </span>
            </b-td>
            <b-td style="min-width: 100px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{ li.ip }}
              </span>
            </b-td>
            <b-td style="min-width: 100px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{ li.ip_address }}
              </span>
            </b-td>
            <b-td style="min-width: 100px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{ li.qx == 'admin' ? '后台管理员' : '代理' }}
              </span>
            </b-td>
            <b-td style="min-width: 100px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                <el-switch :name="li.email" v-model="li.status" @change="dailiCodeChange(index)" active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </span>
            </b-td>
            <b-td style="min-width: 100px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{ li.yut_num }}
              </span>
            </b-td>
            <b-td style="min-width: 200px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{ li.create_time }}
              </span>
            </b-td>

            <b-td class="text-right" style="width: 200px; min-width: 200px;">
              <b-button variant="text" class="p-0 hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-medium"
                style="margin-top: -4px; min-height: 0; " @click="agency_dede(index)">
                <span>删除</span>
              </b-button>
              <b-button variant="text" class="p-0 hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-medium"
                style="margin-top: -4px; min-height: 0; margin-left: 10px;" @click="opendaili(index)">
                <span>修改</span>
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-col>

    <!-- 弹出添加修改 -->
    <el-dialog :title="dialog.id ? '修改' : '添加代理'" :visible.sync="dialogVisible" width="30%">
      <span>
        <el-form ref="form" :model="dialog" label-width="80px">
          <el-form-item label="邮箱">
            <el-input v-model="dialog.email"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="dialog.password"></el-input>
          </el-form-item>
          <el-form-item label="昵称">
            <el-input v-model="dialog.nickname"></el-input>
          </el-form-item>
          <el-form-item label="权限">
            <el-select v-model="dialog.qx" placeholder="请选择权限">
              <el-option label="代理" value="daili"></el-option>
              <el-option label="管理员" value="admin"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="启动">
            <el-switch v-model="dialog.status"></el-switch>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="agencyajax" :loading="dialog.loading">提 交</el-button>
      </span>
    </el-dialog>
  </b-row>
</template>
  
<script>
import {
  BRow,
  BCol,
  BButton,
  BTableSimple,
  BThead,
  BTbody,
  BTh,
  BTr,
  BTd,
  BPagination,
  BFormInput,
  BFormSelect,
  BFormCheckbox,

  BModal,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
    BPagination,
    BFormInput,
    BFormSelect,
    BFormCheckbox,

    BModal
  },
  data() {
    return {
      text: '',
      list: [],

      dialog: {
        id: null,
        email: null,
        nickname: null,
        qx: '',
        password: "",
        status: true,

        loading: false
      },
      dialogVisible: false,
    }
  },
  created() {
    this.loaData();
  },
  watch: {

  },
  methods: {
    async dailiCodeChange(index) {
      var ret = await this.$axios.post('/user_api/set_agent', { id: this.list[index]['id'], operate: 'code', status: this.list[index]['status'] });
      if (ret) {
        this.$message({
          message: ret.msg,
          type: ret.code == 1 ? 'success' : 'error'
        });
        if (ret.code != 1) {
          this.list[index]['status'] = !this.list[index]['status'];
        }
      }
    },

    async agencyajax() {
      var saveData = {
        id: this.dialog.id,
        email: this.dialog.email,
        nickname: this.dialog.nickname,
        qx: this.dialog.qx,
        password: this.dialog.password,
        status: this.dialog.status ? 1 : 0,
        operate: this.dialog.id ? 'set' : 'add',
      };

      this.dialog.loading = true;
      var ret = await this.$axios.post('/user_api/set_agent', saveData);
      this.dialog.loading = false;
      if (ret) {
        this.$message({
          message: ret.msg,
          type: ret.code == 1 ? 'success' : 'error'
        });
        if (ret.code == 1) {
          this.loaData();
          this.dialog = {
            id: null,
            email: null,
            nickname: null,
            qx: '',
            password: "",
            status: true,

            loading: false
          };
          this.dialogVisible = false;
        }
      }
    },

    async agency_dede(index) {
      var ret = await this.$axios.post('/user_api/set_agent', {
        operate: 'dede',
        id: this.list[index]['id']
      });
      if (ret) {
        this.$message({
          message: ret.msg,
          type: ret.code == 1 ? 'success' : 'error'
        });
        if (ret.code == 1) {
          this.loaData();
        }
      }
    },

    async loaData() {
      var ret = await this.$axios.post('/user_api/agent_list', { text: this.text });
      if (ret) {
        if (ret.code == 1) {
          for (const key in ret.list) {
            ret.list[key]['status'] = ret.list[key]['status'] == 1 ? true : false;
          }
          this.list = ret.list;
        }
      }
    },


    opendaili(index) {
      var dialog = {
        id: this.list[index]['id'],
        email: this.list[index]['email'],
        nickname: this.list[index]['nickname'],
        qx: this.list[index]['qx'],
        password: "",
        status: this.list[index]['status'],
        loading: false
      };
      this.dialog = dialog;
      this.dialogVisible = true;
    }
  }
};
</script>
  